import React from "react"
import Link from '../utils/link'

import Seo from "../components/seo"

const ThankYou = () => (
  <>
    <Seo title="Thank You" bodyClass='thank-you' />
    <section className='thanks'>
  		<div className='thanks__content'>
		    <h2><span className='border-ani-active'>Thank you</span> <br />for subscribing</h2>
		    <Link to='/' className='btn'>Back</Link>
		  </div>
		</section>
  </>
)

export default ThankYou